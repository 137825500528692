//
// accordions.scss
//

.custom-accordion {
    .card {
        box-shadow: none;
    }

    .card-header {
        background-color: #{map-get($grays, '100')};
    }

    .card-body {
        border: #{($border-width) solid map-get($grays, '100')};
    }

    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: '\F142';
                }
            }
        }
    }
}

.custom-accordion-title {
    color: $custom-accordion-title-color;
    position: relative;

    &:hover {
        color: #{lighten(map-get($grays, '900'), 7.5%)};
    }
}

.accordion {
    > .card {
        > .card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
    }
}

// Dark mode
body[data-layout-color='dark'] {
    .custom-accordion {
        .card-header {
            background-color: #{map-get($dark-grays, '100')};
        }
        .card-body {
            border: #{($border-width) solid map-get($dark-grays, '100')};
        }
    }
    .custom-accordion-title {
        &:hover {
            color: #{lighten(map-get($dark-grays, '400'), 7.5%)};
        }
    }
}

.skeleton-large-div {
    flex: 1;
}

// Hide Element on MobileView
.element-to-hide {
    /* Your styles go here */
}

/* Media query to hide the element on mobile devices */
@media (max-width: 850px) {
    .element-to-hide {
        display: none;
    }
}

/* Styles to hide the element by default */
.show-on-mobile-view {
    display: none;
}

// verical hr
.vertical-hr {
    height: 100px; /* Height of the vertical line */
    border: none; /* Remove default border */
    border-left: 2px solid black; /* Create a vertical line */
    transform: rotate(90deg); /* Rotate the line to make it vertical */
    transform-origin: left center; /* Rotate from the left edge */
}

@media (max-width: 850px) {
    .show-on-mobile-view {
        display: flex;
    }
    .fixed-custom-btn {
        position: absolute;
        bottom: 3.5rem;
        width: calc(100vw - 3rem);
        white-space: nowrap;
        }
}

@media screen and (orientation: landscape) {
    .fixed-custom-btn {
        position: relative;
        bottom: 0;
        width: 94vw;
    }
}

.questions-div {
    max-height: '70vh';
    width: '100%';
}

@media (max-width: 850px) {
    .questions-div {
        max-height: '60vh';
        width: '100%';
    }
}

.view-code-btns {
    position: absolute;
    bottom: 3.5rem;
    // padding-right: 3rem;
    width:94vw;
}

@media screen and (orientation:landscape){
    .view-code-btns{
        position: relative;
        bottom:0;
    }
}
